import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  Label
} from "reactstrap";

import { API_URL } from '../utils/api';

class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPrice: false,
      editingWallet: false,
      wallet: '',
      editPriceId: '',
      editPriceFrom: '',
      editPricePrice: '',
      prices: [],
      globalAdsPrice: '',
      editGlobalPrice: false
    }
  }
  
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  toggleModalPrice = () => {
    this.setState({
      modalPrice: !this.state.modalPrice
    });
  }

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  prepareEditPrice = (_id, from, price) => {
    this.setState({
      editPriceId: _id,
      editPriceFrom: from,
      editPricePrice: price
    });
    this.toggleModalPrice();
  }

  toggleGlobalPriceEdit = () => {
    this.setState({
      editGlobalPrice: !this.state.editGlobalPrice
    });
  }

  editPrice = (e) => {
    e.preventDefault();
    const { editPriceId, editPriceFrom, editPricePrice } = this.state;
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/edit-price`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: editPriceId, from: editPriceFrom, price: this.formatPriceTo(editPricePrice)})
    }).then(this.handleErrors)
    .then( response => {
      this.toggleModalPrice();
      const updatedPrices = [...this.state.prices]; 
      let changeIndex = updatedPrices.findIndex(price => price._id === editPriceId);
      updatedPrices[changeIndex].from = editPriceFrom;
      updatedPrices[changeIndex].price = this.formatPriceTo(editPricePrice);
      this.setState({
        editPriceId: '',
        editPriceFrom: '',
        editPricePrice: '',
        prices: updatedPrices
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        editPriceId: '',
        editPriceFrom: '',
        editPricePrice: ''
      });
    });
  }

  getPrices = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/prices`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        prices: data[0],
        wallet: data[1].wallet,
        globalAdsPrice: this.formatPriceFrom(data[1].globalPrice)
      });
    }).catch(error => {
      console.log(error);
    });
  };


  deletePrice = (_id) => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/delete-price`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: _id})
    }).then(this.handleErrors)
    .then( response => {
      const updatedPrices = this.state.prices.filter(item => item._id !== _id);
      this.setState({
        prices: updatedPrices
      });
    }).catch(error => {
      console.log(error);
    });
  };

  addPrice = (e) => {
    e.preventDefault();
    const { botURL } = this.props;
    const { editPriceFrom, editPricePrice} = this.state;
    fetch(`${API_URL}/${botURL}/add-price`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({from: editPriceFrom, price: this.formatPriceTo(editPricePrice)})
    }).then(this.handleErrors)
    .then( response => {
      const updatedPrices = [{from: editPriceFrom, price: editPricePrice, _id: 0}, ...this.state.prices];
      this.setState({
        prices: updatedPrices
      });
      this.toggleModalPrice();
    }).catch(error => {
      console.log(error);
    });
  }

  saveWalletHandler = () => {
    const { wallet } = this.state;
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/edit-wallet`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({wallet: wallet})
    }).then(this.handleErrors)
    .then( response => {
      this.toggleWalletEdit();
    }).catch(error => {
      console.log(error);
    });
  }

  saveGlobalPrice = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/edit-global`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({price: this.formatPriceTo(this.state.globalAdsPrice)})
    }).then(this.handleErrors)
    .then( response => {
      this.toggleGlobalPriceEdit();
    }).catch(error => {
      console.log(error);
    });
  }

  toggleWalletEdit = () => {
    this.setState({
      editingWallet: !this.state.editingWallet
    });
  }

  formatPriceFrom = price => price/1000000000000000000;

  formatPriceTo = price => price*1000000000000000000;

  componentDidMount () {
    this.getPrices(); 
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.prices.length !== this.state.prices.length
      || prevProps.botURL !== this.props.botURL) {
      this.getPrices();
    }
  }

  render() {
    const { editingWallet, wallet} = this.state;
    let walletView = (
      <div className="d-flex align-items-center justify-content-between">
        <p>{wallet}</p>
        <Button 
          color="info"
          className="btn-icon btn-simple"
          onClick={this.toggleWalletEdit}
        ><i className="fa fa-edit"></i></Button>
      </div>
    );
    
    if (editingWallet) {
      walletView = (
        <InputGroup>
          <Input
            type="text"
            name="wallet"
            value={wallet}
            onChange={this.onChange}
          />
          <InputGroupAddon>
            <Button 
              color="success" 
              className="btn-icon btn-simple m-0"
              onClick={this.saveWalletHandler}
            ><i className="fas fa-check" /></Button>
          </InputGroupAddon>
        </InputGroup>
      );
    }

    return (
      <>
        <div className="content">
          <Row className="row-equal-heigth">
            <Col sm={{size: 5, offset: 1}}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Current Wallet</CardTitle>
                </CardHeader>
                <CardBody>
                  {walletView}
                </CardBody>
              </Card>
            </Col>
            <Col sm="5" className="text-right mb-3">
              <Button 
                color="info"
                onClick={() => {
                  this.setState({
                    editPriceId: '',
                    editPriceFrom: '',
                    editPricePrice: ''
                  });
                  this.toggleModalPrice();}}
                >
                Add Price
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={{offset: 1, size: 10}}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Keyword Ads Prices</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>From</th>
                        <th>Price</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.prices.map(price => {
                        return (
                          <tr key={price._id}>
                            <td>{price.from}</td>
                            <td>{this.formatPriceFrom(price.price)}</td>
                            <td className="text-right">
                              <Button className="btn-icon btn-simple" color="info" size="sm" onClick={() => {this.prepareEditPrice(price._id, price.from, this.formatPriceFrom(price.price))}}>
                                <i className="fa fa-edit"></i>
                              </Button>{` `}
                              <Button className="btn-icon btn-simple" color="danger" size="sm" onClick={() => {this.deletePrice(price._id)}}>
                                <i className="fa fa-times" />
                              </Button>{` `}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col sm={{offset: 1, size: 10}}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Global Ads Price</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Price</th>
                        <th>{this.state.editGlobalPrice ? 
                          <Input 
                            type="number"
                            id="global-price"
                            name="globalAdsPrice"
                            value={this.state.globalAdsPrice}
                            onChange={this.onChange}
                          /> :
                          this.state.globalAdsPrice
                        }</th>
                        <th>
                          {this.state.editGlobalPrice ?
                          <Button className="btn-simple" color="info" size="sm" onClick={this.saveGlobalPrice}>
                            Save
                          </Button> :
                          <Button className="btn-icon btn-simple" color="info" size="sm" onClick={this.toggleGlobalPriceEdit}>
                            <i className="fa fa-edit"></i>
                          </Button>}
                        </th>
                      </tr>
                    </thead>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          modalClassName="modal-black"
          isOpen={this.state.modalPrice}
          toggle={this.toggleModalPrice}
        >
          <div className="modal-header">
            <h5 className="modal-title">{this.state.editPriceId ? "Edit Price" : "Add New Price"}</h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalPrice}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
            <form onSubmit={this.state.editPriceId ? this.editPrice : this.addPrice}>
              <Row>
                <FormGroup className="col-12">
                  <Label>From</Label>
                  <Input 
                    type="number"
                    id="price-from"
                    placeholder="From"
                    name="editPriceFrom"
                    value={this.state.editPriceFrom}
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="col-12">
                  <Label>Price</Label>
                  <Input 
                    type="number"
                    id="price-price"
                    placeholder="Price"
                    name="editPricePrice"
                    value={this.state.editPricePrice}
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Row>
              <div className="text-right">
                <Button
                  color="primary"
                  type="submit"
                  onClick={this.state.editPriceId ? this.editPrice : this.addPrice}
                >
                  Save
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Prices;
