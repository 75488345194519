import Dashboard from "views/Dashboard.jsx";
import Database from "views/Database.jsx";
import Keywords from "views/Keywords.jsx";
import Labels from "views/Labels.jsx";
import Blacklist from "views/Blacklist.jsx";
import Admin from "views/Admin.jsx";
import Prices from "views/Prices.jsx";
import GlobalAds from "views/GlobalAds.jsx";
import Blackwords from 'views/Blackwords.jsx';
import Balances from 'views/Balances.jsx';
import Deposits from 'views/Deposits.jsx';

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    adminLevel: 3
  },
  {
    path: "/database",
    name: "Database",
    icon: "tim-icons icon-coins",
    component: Database,
    layout: "/admin",
    adminLevel: 3
  },
  {
    path: "/keywords",
    name: "Keywords",
    icon: "tim-icons icon-key-25",
    component: Keywords,
    layout: "/admin",
    adminLevel: 1
  },
  {
    path: "/labels",
    name: "Labels",
    icon: "tim-icons icon-tag",
    component: Labels,
    layout: "/admin",
    adminLevel: 1
  },
  {
    path: "/blacklist",
    name: "Blacklist",
    icon: "tim-icons icon-paper",
    component: Blacklist,
    layout: "/admin",
    adminLevel: 1
  },
  {
    path: "/admins",
    name: "Admin",
    icon: "tim-icons icon-single-02",
    component: Admin,
    layout: "/admin",
    adminLevel: 1
  },
  {
    path: "/prices",
    name: "Prices",
    icon: "tim-icons icon-money-coins",
    component: Prices,
    layout: "/admin",
    adminLevel: 1
  },
  {
    path: "/global-ads",
    name: "Global Ads",
    icon: "tim-icons icon-world",
    component: GlobalAds,
    layout: "/admin",
    adminLevel: 1
  },
  {
    path: "/blackwords",
    name: "Blackwords",
    icon: "tim-icons icon-simple-remove",
    component: Blackwords,
    layout: "/admin",
    adminLevel: 1
  },
  {
    path: "/balances",
    name: "Balances",
    icon: "tim-icons icon-wallet-43",
    component: Balances,
    layout: "/admin",
    adminLevel: 1
  },
  {
    path: "/deposits",
    name: "Deposits",
    icon: "tim-icons icon-notes",
    component: Deposits,
    layout: "/admin",
    adminLevel: 1
  }
];
export default routes;
