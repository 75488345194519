import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
  Label,
  Alert
} from "reactstrap";

import { API_URL } from '../utils/api';

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      modalAdmin: false,
      adminList: [],
      logs: [],
      actions: [],
      bots: [],
      newAdminLevel: 1,
      newAdminUsername: '',
      newAdminPassword: '',
      newAdminBots: []
    }
  }

  modalInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  toggleModalAddAdmin = () => {
    if (this.state.modalAdmin) {
      this.setState({
        newAdminLevel: 1,
        newAdminUsername: '',
        newAdminPassword: '',
        newAdminBots: []
      });
    }
    this.setState({
      modalAdmin: !this.state.modalAdmin
    });
  }

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  alertOnDismiss = () => {
    this.setState({
      alert: false
    });
  }

  parseParams = paramsString => {
    let newParamsString = paramsString.replace(/['"{}]/g, "");
    const paramsArray = newParamsString.split(',');
    return (
      <p>
        {paramsArray.map((param, key) => <span key={key}>{param.replace(/[:]/g, " = ")}<br/></span>)}
      </p>
    );
  }

  parseDate = date => {
    const dateToParse = new Date(date);
    
    const y = dateToParse.getFullYear();
    const m = ("0" + (dateToParse.getUTCMonth() + 1)).slice(-2);
    const d = ("0" + dateToParse.getUTCDate()).slice(-2);
    const h = ("0" + dateToParse.getUTCHours()).slice(-2);
    const mi = ("0" + dateToParse.getUTCMinutes()).slice(-2);
    const s = ("0" + dateToParse.getUTCSeconds()).slice(-2);
  
    return `${h}:${mi}:${s}, ${d}/${m}/${y}`;
  }

  createAdmin = (e) => {
    e.preventDefault();
    const {newAdminLevel, newAdminUsername, newAdminPassword, newAdminBots } = this.state;
    fetch(`${API_URL}/authAPI/register`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${this.props.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: newAdminUsername, password: newAdminPassword, level: newAdminLevel, bots: newAdminBots})
    }).then(this.handleErrors)
    .then( response => {
      this.getAdmin();
      this.toggleModalAddAdmin();
      this.setState({
        newAdminLevel: 1,
        newAdminUsername: '',
        newAdminPassword: '',
        newAdminBots: []
      });
    }).catch(error => {
      this.setState({
        alert: true
      });
    });
  }

  deleteAdmin = (adminId) => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/delete-admin`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${this.props.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: adminId})
    }).then(this.handleErrors)
    .then( response => {
      let updatedAdminList = this.state.adminList.filter(admin => admin._id !== adminId);
      this.setState({
        adminList: updatedAdminList
      });
    }).catch(error => {
      console.log(error);
    });
  }

  getAdmin = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/admin`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        adminList: data[0],
        logs: data[1],
        actions: data[2],
        bots: data[3]
      });
    }).catch(error => {
      console.log(error);
    });
  }

  handleAction = (actionId, handleWay) => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/${handleWay}`, {
      method: "POST",
      headers: {
        'Authorization': `Token ${this.props.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: actionId})
    }).then(this.handleErrors)
    .then( response => {
      const updatedActions = this.state.actions.filter(action => action._id !== actionId);
      this.setState({
        actions: updatedActions
      });
    }).catch(error => {
      console.log(error);
    });
  }

  addBotsToList = (id) => {
    const updatedBots = this.state.newAdminBots;
    updatedBots.push(id);
    this.setState({
      newAdminBots: updatedBots
    });
  }

  removeBotFromList = (id) => {
    const updatedBots = this.state.newAdminBots.filter(item => id !== item);
    this.setState({
      newAdminBots: updatedBots
    });
  }

  componentDidMount () {
    this.getAdmin(); 
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.adminList.length !== this.state.adminList.length) {
      this.getAdmin();
    }
    if (prevProps.botURL !== this.props.botURL) {
      this.getAdmin();
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="row-equal-heigth">
            <Col className="text-right mb-3">
              <Button 
                color="info"
                onClick={this.toggleModalAddAdmin}
                >
                Add Admin
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle tag="h2">Admins</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter ps-child" responsive>
                  <thead>
                      <tr>
                        <th>Username</th>
                        <th className="text-right">Level</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.adminList.map(admin => {
                        return (
                          <tr key={admin._id}>
                            <td>{admin.username}</td>
                            <td className="text-right">{admin.level}</td>
                            <td className="text-right">
                              <Button className="btn-icon btn-simple" color="danger" size="sm" onClick={() => {this.deleteAdmin(admin._id)}}>
                                <i className="fa fa-times" />
                              </Button>{` `}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="5">
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle tag="h2">Logs</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter ps-child" responsive>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Action</th>
                        <th className="text-right">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.logs.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.username}</td>
                            <td>{item.action}</td>
                            <td className="text-right">{this.parseDate(item.time)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col sm="7">
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle tag="h2">Actions</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter ps-child" responsive>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Action</th>
                        <th>Params</th>
                        <th className="text-right">Time</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.actions.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.username}</td>
                            <td>{item.action}</td>
                            <td>{item.params ? this.parseParams(item.params) : ""}</td>
                            <td className="text-right">{this.parseDate(item.time)}</td>
                            <td>
                              <Button className="btn-icon btn-simple" color="success" size="sm" onClick={() => {this.handleAction(item._id, 'run-action')}}>
                                <i className="fas fa-check" />
                              </Button>{` `}
                              <Button className="btn-icon btn-simple" color="danger" size="sm" onClick={() => {this.handleAction(item._id, 'delete-action')}}>
                                <i className="fa fa-times" />
                              </Button>{` `}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="react-notification-alert-container">
            <Alert color="danger" isOpen={this.state.alert} toggle={this.alertOnDismiss} className="alert-dismissible">
              Admin with username <strong>{this.state.newAdminUsername}</strong> is already existing!
            </Alert>
          </div>
        </div>
        <Modal
          modalClassName="modal-add-label modal-black"
          isOpen={this.state.modalAdmin}
          toggle={this.toggleModalAddAdmin}
        >
          <div className="modal-header">
                    <h5 className="modal-title">Add New Admin</h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalAddAdmin}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
            <form onSubmit={this.createAdmin}>
              <Row>
                <FormGroup className="col-12">
                  <Label>Username</Label>
                  <Input 
                    type="text"
                    id="username"
                    placeholder="Username"
                    name="newAdminUsername"
                    onChange={this.modalInputChange}
                  />
                </FormGroup>
                <FormGroup className="col-12">
                  <Label>Password</Label>
                  <Input 
                    type="text"
                    id="password"
                    placeholder="Password"
                    name="newAdminPassword"
                    onChange={this.modalInputChange}
                  />
                </FormGroup>
                <FormGroup className="col-12">
                  <Label className="d-block">Bots</Label>
                  {this.state.bots.map((item) => {
                    return (
                      this.state.newAdminBots.find(currVal => item._id === currVal) ?
                      <Button key={item._id} color="success" className="btn-round" size="sm" onClick={() => {this.removeBotFromList(item._id)}}>{item.name}</Button> :
                      <Button key={item._id} color="default" className="btn-round" size="sm" onClick={() => {this.addBotsToList(item._id)}}>{item.name}</Button>
                    );
                  })}
                </FormGroup>
                <FormGroup className="col-12">
                  <Row>
                    <Col>
                      <Label>Level</Label>
                    </Col>
                  </Row>
                  <FormGroup check inline>
                    <Input 
                      type="radio"
                      name="newAdminLevel"
                      id="admin-level-1"
                      onChange={this.modalInputChange}
                      value="1"
                    />
                    <Label check for="admin-level-1">1</Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input 
                      type="radio"
                      name="newAdminLevel"
                      id="admin-level-2"
                      onChange={this.modalInputChange}
                      value="2"
                    />
                    <Label check for="admin-level-2">2</Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input 
                      type="radio"
                      name="newAdminLevel"
                      id="admin-level-3"
                      onChange={this.modalInputChange}
                      value="3"
                    />
                    <Label check for="admin-level-3">3</Label>
                  </FormGroup>
                </FormGroup>
              </Row>
              <div className="text-right">
                <Button
                  color="primary"
                  type="submit"
                  onClick={this.createAdmin}
                >
                  Save
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Admin;
