import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Container
} from "reactstrap";

import { API_URL } from '../../utils/api';

class Login extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      login: '',
      password: ''
    }
  }

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = (e) => {
    const {handleLogin, history} = this.props;

    e.preventDefault();
    fetch(`${API_URL}/authAPI/login`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: this.state.login, password: this.state.password})
    })
    .then(this.handleErrors)
    .then(response => {
      return response.json();
    }).then(data => {
      handleLogin(data);
      history.push('/admin/dashboard');
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <Container>
        <Row className="mt-5">
          <Col md={{ size: 6, offset: 3 }}>
            <Card className="pr-5 pl-5">
              <CardHeader>
                <CardTitle tag="h3" className="text-center">Login</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="login-password">Login</Label>
                    <Input
                      type="text"
                      name="login"
                      id="login"
                      placeholder="Login"
                      autoComplete="off"
                      value={this.state.login}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="login-password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      autoComplete="off"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <div className="text-center">
                    <Button color="primary" type="submit" onClick={this.handleSubmit}>
                      Submit
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;