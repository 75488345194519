import React from "react";

import {
  Button,
  Card,
  CardBody,
  Input,
  Table,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import { API_URL } from '../utils/api';

class Blacklist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blacklistItems: [],
      deleteItem: '',
      searchPhrase: ''
    }
  }

  onFocus = () => {
    this.setState({
      focused: "input-group-focus"
    });
  };

  onBlur = (e) => {
    this.setState({
      focused: ""
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  getBlacklist = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/blacklist-search?search=${this.state.searchPhrase}`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        blacklistItems: data
      });
    }).catch(error => {
      console.log(error);
    });
  };

  deleteItem = (_id) => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/delete-blacklist`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: _id})
    }).then(this.handleErrors)
    .then( response => {
      const updatedBlacklist = this.state.blacklistItems.filter(item => item._id !== _id);
      this.setState({
        blacklistItems: updatedBlacklist
      });
    }).catch(error => {
      console.log(error);
    });
  };

  componentDidMount () {
    this.getBlacklist();
  }

  componentDidUpdate (prevProps, prevState) {
    const { botURL } = this.props;
    if (prevState.searchPhrase !== this.state.searchPhrase) {
      fetch(`${API_URL}/${botURL}/blacklist-search?search=${this.state.searchPhrase}`, {
        headers: {
          'Authorization': `Token ${this.props.token}`
        }
      }).then(this.handleErrors)
      .then( response => {
        console.log(response);
        return response.json();
      }).then(data => {
        this.setState({
          blacklistItems: data
        });
      }).catch(error => {
        console.log(error);
      });
    }
    if (prevProps.botURL !== this.props.botURL) {
      this.getBlacklist();
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="row-equal-heigth">
            <Col sm={{offset: 1, size: 5}}>
              <Card>
                <CardBody>  
                  <InputGroup className={this.state.focused}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-zoom-split" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.onChange}
                      name="searchPhrase"
                      type="search"
                      placeholder="Search..."
                      id="search-phrase"

                    />
                  </InputGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{offset: 1, size: 10}}>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.blacklistItems.map(item => {
                        return (
                          <tr key={item._id}>
                            <td>{item.username}</td>
                            <td className="text-right">
                              <Button className="btn-icon btn-simple" color="danger" size="sm" onClick={() => {this.deleteItem(item._id)}}>
                                <i className="fa fa-times" />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Blacklist;
