import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  ModalBody
} from "reactstrap";

import { API_URL } from '../utils/api';

class Labels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLabel: false,
      labels: [],
      newLabelName: '',
      editLabelId: ''
    }
  }

  modalInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  toggleModalLabel = () => {
    this.setState({
      modalLabel: !this.state.modalLabel
    });
  };

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  prepareEditLabel = (_id, name) => {
    this.setState({
      editLabelId: _id,
      newLabelName: name
    });
    this.toggleModalLabel();
  }

  editLabel = (e) => {
    e.preventDefault();
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/rename-lable`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: this.state.editLabelId, name: this.state.newLabelName})
    }).then(this.handleErrors)
    .then( response => {
      this.toggleModalLabel();
      let updatedLabels = this.state.labels;
      const changeIndex = this.state.labels.findIndex(item => item._id === this.state.editLabelId);
      updatedLabels[changeIndex].name = this.state.newLabelName;
      this.setState({
        editLabelId: '',
        newLabelName: '',
        labels: updatedLabels
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        editLabelId: '',
        newLabelName: ''
      });
    });
  }

  getLabels = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/lable`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        labels: data
      });
    }).catch(error => {
      console.log(error);
    });
  };

  deleteLabel = (_id) => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/delete-lable`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: _id})
    }).then(this.handleErrors)
    .then( response => {
      const updatedLabels = this.state.labels.filter(item => item._id !== _id);
      this.setState({
        labels: updatedLabels
      });
    }).catch(error => {
      console.log(error);
    });
  };

  addLabel = (e) => {
    e.preventDefault();
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/create-lable`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({name: this.state.newLabelName})
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      const updatedLabels = [{name: this.state.newLabelName, _id: data}, ...this.state.labels];
      this.setState({
        labels: updatedLabels
      });
      this.toggleModalLabel();
    }).catch(error => {
      console.log(error);
    });
  }

  componentDidMount () {
    this.getLabels(); 
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.botURL !== this.props.botURL) {
      this.getlabels();
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="row-equal-heigth">
            <Col sm={{offset: 1, size: 10}} className="text-right mb-3">
              <Button 
                color="info"
                onClick={() => {
                  this.setState({
                    editLabelId: '',
                    newLabelName: ''
                  });
                  this.toggleModalLabel();}}
                >
                Add Label
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={{offset: 1, size: 10}}>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Label</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.labels.map(label => {
                        return (
                          <tr key={label._id}>
                            <td>{label.name}</td>
                            <td className="text-right">
                              <Button className="btn-icon btn-simple" color="info" size="sm" onClick={() => {this.prepareEditLabel(label._id, label.name)}}>
                                <i className="fa fa-edit"></i>
                              </Button>{` `}
                              <Button className="btn-icon btn-simple" color="danger" size="sm" onClick={() => {this.deleteLabel(label._id)}}>
                                <i className="fa fa-times" />
                              </Button>{` `}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          modalClassName="modal-add-label modal-black"
          isOpen={this.state.modalLabel}
          toggle={this.toggleModalLabel}
        >
          <div className="modal-header">
            <h5 className="modal-title">{this.state.editLabelId ? "Edit Label" : "Add New Label"}</h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalLabel}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
            <form onSubmit={this.state.editLabelId ? this.editLabel : this.addLabel}>
              <Row>
                <FormGroup className="col-12">
                  <Input 
                    type="text"
                    id="label-name"
                    placeholder="Name"
                    name="newLabelName"
                    value={this.state.newLabelName}
                    onChange={this.modalInputChange}
                  />
                </FormGroup>
              </Row>
              <div className="text-right">
                <Button
                  color="primary"
                  type="submit"
                  onClick={this.state.editLabelId ? this.editLabel : this.addLabel}
                >
                  Save
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Labels;
