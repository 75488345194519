import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  NavLink,
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import { API_URL } from '../utils/api';

class GlobalAds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalAds: [],
      selectedDateInfo: {},
      editMode: false,
      editText: '',
      editURL: '',
      editStatus: 0,
      editPrice: 0
    }
  }

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  getGlobalAds = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/globalad`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        globalAds: data[0],
        selectedDateInfo: data[0][0],
        editText: data[0][0].text || '',
        editURL: data[0][0].url || '',
        editStatus: data[0][0].status,
        editPrice: this.formatPriceFrom(data[0][0].price) || ''
      });
    }).catch(error => {
      console.log(error);
    });
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  toggleEdit = () => {
    this.setState({
      editMode: !this.state.editMode
    });
  }

  editDate = () => {
    const { editText, editURL, editStatus, editPrice, selectedDateInfo, globalAds } = this.state;
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/edit-date`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${this.props.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: selectedDateInfo._id, text: editText, url: editURL, status: editStatus, price: this.formatPriceTo(editPrice)})
    }).then(this.handleErrors)
    .then( response => {
      const updatedAds = globalAds;
      const editAdIndex = globalAds.findIndex(item => item._id === selectedDateInfo._id);
      const editAd = globalAds[editAdIndex];
      editAd.text = editText;
      editAd.url = editURL;
      editAd.status = editStatus;
      editAd.price = this.formatPriceTo(editPrice);
      updatedAds.splice(editAdIndex, 1, editAd);
      this.setState({
        globalAds: updatedAds,
        selectedDateInfo: updatedAds[editAdIndex]
      });
      this.toggleEdit();
    }).catch(error => {
      console.log(error);
    });
  }

  dateClickHandler = (dateId) => {
    this.setState({
      editMode: false
    });
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/getDate?id=${dateId}`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        selectedDateInfo: data,
        editText: data.text || '',
        editURL: data.url || '',
        editStatus: data.status || 0,
        editPrice: data.price || ''
      });
    }).catch(error => {
      console.log(error);
    });
  }

  formatPriceFrom = price => price/1000000000000000000;

  formatPriceTo = price => price*1000000000000000000;

  parseDate = date => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString('en-GB');
  }

  componentDidMount () {
    this.getGlobalAds();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.botURL !== this.props.botURL) {
      this.getGlobalAds();
    }
  }

  render() {
    const { globalAds, selectedDateInfo, editMode, editText, editURL, editStatus, editPrice } = this.state;

    let dates = globalAds.map((date, index) => 
      <Button 
        size="sm"
        key={index} 
        color={date._id === selectedDateInfo._id ? "success" : "default"} 
        onClick={() => this.dateClickHandler(date._id)}
        >
          {this.parseDate(date.date)}
      </Button>
    );

    const dateView = (
      <Row>
        <Col lg="2">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Date</h5>
              <CardTitle tag="h3">
                {this.parseDate(selectedDateInfo.date)}
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
        <Col lg="2">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Price</h5>
              {editMode ?   
                <Input 
                  type="number"
                  name="editPrice"
                  className="mb-2"
                  placeholder="Price"
                  onChange={this.handleInputChange}
                  value={editPrice}
                />
                : <CardTitle tag="h3">
                    {this.formatPriceFrom(selectedDateInfo.price) || "Empty"}
                  </CardTitle>} 
            </CardHeader>
          </Card>
        </Col>
        <Col lg="3">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Text</h5>
              {editMode ?   
                <Input 
                  type="text"
                  name="editText"
                  className="mb-2"
                  placeholder="Text"
                  onChange={this.handleInputChange}
                  value={editText}
                />
                : <CardTitle tag="h3">
                    {selectedDateInfo.text || "Empty"}
                  </CardTitle>}            
            </CardHeader>
          </Card>
        </Col>
        <Col lg="2">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Telegram ID</h5>
              <CardTitle tag="h3">
                {selectedDateInfo.tgId || "Empty"}
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
        <Col lg="3">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">URL</h5>
                {editMode ? 
                  <Input 
                    type="text"
                    name="editURL"
                    placeholder="URL"
                    onChange={this.handleInputChange}
                    className="mb-2"
                    value={editURL}
                  />
                : <div>
                  {selectedDateInfo.url && <NavLink href={selectedDateInfo.url} target="blank">{selectedDateInfo.url}</NavLink>}
                  <CardTitle tag="h3">{!selectedDateInfo.url && "Empty"}</CardTitle>
                </div>} 
            </CardHeader>
          </Card>
        </Col>
        {editMode ?
        <Col sm="3">
          <Card className="card-chart">
            <CardHeader className="mb-2">
              <h5 className="card-category">Status</h5>
              <FormGroup check inline>
                <Input 
                  type="radio"
                  name="editStatus"
                  id="status-active"
                  onChange={this.handleInputChange}
                  value={1}
                  checked={editStatus === "1"}
                />
                <Label check for="status-active">Active</Label>
              </FormGroup>
              <FormGroup check inline>
                <Input 
                  type="radio"
                  name="editStatus"
                  id="status-suspended"
                  onChange={this.handleInputChange}
                  value={0}
                  checked={editStatus === "0"}
                />
                <Label check for="status-suspended">Suspended</Label>
              </FormGroup>
            </CardHeader>
          </Card>
        </Col> : null}
        <Col sm={editMode ? 9 : 12} className="text-right">
          {editMode ? 
          <Button color="info" onClick={this.editDate}>Save</Button> : 
          <Button color="info" onClick={this.toggleEdit}>Edit</Button> }
        </Col>
      </Row>
    );


    return (
      <>
        <div className="content">
          <Row className="row-equal-heigth">
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Dates</CardTitle>
                </CardHeader>
                <CardBody>
                  {dates}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {dateView}
        </div>
      </>
    );
  }
}

export default GlobalAds;
