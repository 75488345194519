import React from 'react';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import Pagination from '../components/Pagination/Pagination.jsx';

import { API_URL } from '../utils/api';

class Balances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balances: [],
      page: 1,
      total_pages: 1,
      addId: '',
      addVal: 0,
      modal: false,
      user: '',
      search: '',
      focused: ''
    }
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  switchPage = page => {
    this.setState({
      page: page
    });
  };

  onFocus = () => {
    this.setState({
      focused: "input-group-focus"
    });
  };

  onBlur = (e) => {
    this.setState({
      focused: ""
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  prepareEditLabel = (_id, user) => {
    this.setState({
      addId: _id,
      user: user
    });
    this.toggleModal();
  }

  formatPriceFrom = price => price/1000000000000000000;

  formatPriceTo = price => price*1000000000000000000;

  addBalance = (e) => {
    e.preventDefault();
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/add-balance`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: this.state.addId, value: this.state.addVal})
    }).then(this.handleErrors)
    .then( response => {
      this.toggleModal();
      let updatedBalances = [...this.state.balances];
      const changeIndex = updatedBalances.findIndex(item => item._id === this.state.addId);
      updatedBalances[changeIndex].balance += this.formatPriceTo(this.state.addVal);
      this.setState({
        addId: '',
        addVal: 0,
        user: '',
        balances: updatedBalances
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        addId: '',
        addVal: 0,
        user: ''
      });
    });
  }

  getBalances = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/balances?page=${this.state.page}&search=${this.state.search}`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        page: data[0].page,
        total_pages: data[0].pages,
        balances: data[1]
      });
    }).catch(error => {
      console.log(error);
    });
  };

  componentDidMount () {
    this.getBalances(); 
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.botURL !== this.props.botURL ||
      prevState.search !== this.state.search ||
      prevState.page !== this.state.page) {
      this.getBalances();
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="row-equal-heigth">
            <Col sm={{offset: 1, size: 5}}>
              <Card>
                <CardBody>  
                  <InputGroup className={this.state.focused}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-zoom-split" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.onChange}
                      name="search"
                      type="search"
                      placeholder="Search..."
                      id="search-phrase"
                    />
                  </InputGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{offset: 1, size: 10}}>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Telegram ID</th>
                        <th>Balance</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.balances.map(user => {
                        return (
                          <tr key={user._id}>
                            <td>{user.tgId}</td>
                            <td>{this.formatPriceFrom(user.balance)} ETH</td>
                            <td className="text-right">
                              <Button className="btn-icon btn-simple" color="info" size="sm" onClick={() => {this.prepareEditLabel(user._id, user.tgId)}}>
                                <i className="fa fa-edit"></i>
                              </Button>{` `}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-center">
                    <Pagination page={parseInt(this.state.page)} total_pages={this.state.total_pages} switchPage={this.switchPage} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          modalClassName="modal-black"
          isOpen={this.state.modal}
          toggle={this.toggleModal}
        >
          <div className="modal-header">
            <h5 className="modal-title">Add balance for user: {this.state.user}</h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModal}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
            <form onSubmit={this.addBalance}>
              <Row>
                <FormGroup className="col-12">
                  <Input 
                    type="number"
                    placeholder="Balance"
                    name="addVal"
                    value={this.state.addVal}
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Row>
              <div className="text-right">
                <Button
                  color="primary"
                  type="submit"
                  onClick={this.addBalance}
                >
                  Save
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Balances;