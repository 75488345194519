import React from "react";
import classNames from "classnames";
import { Line } from "react-chartjs-2";

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Label,
  Input
} from "reactstrap";

// core components
import {
  chart_options
} from "variables/charts.js";

import { API_URL } from '../utils/api';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      followers: 0,
      chatsAmount: 0,
      usersToday: 0,
      searchAmount: 0,
      itemsToday: 0,
      words10: [],
      words50: [],
      chartName: 'Users',
      chartLabels: [],
      chartData: [],
      datasetUsers: [],
      datasetSearches: [],
      datasetItems: [],
      datasetFollowers: [],
      chats: [],
      dateFrom: '',
      dateTo: '',
    };
  }

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  getData = () => {
    const { botURL } = this.props;
    let labels = [], usersData = [], searchesData = [], todayItems = [], followers = [];
    fetch(`${API_URL}/${botURL}/dashboard`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      const sorted10Words = data[2][0].words.sort( (a, b) => {
        return b.searches - a.searches;
      });
      const sorted50Words = data[3].sort( (a, b) => {
        return b.searches - a.searches;
      });
      data[2].map(item => {
        labels.push(item.date);
        usersData.push(item.usersAmount);
        searchesData.push(item.search);
        todayItems.push(item.addedChats || 0);
        followers.push(item.newusers);
      });
      labels = labels.reverse();
      usersData = usersData.reverse();
      searchesData = searchesData.reverse();
      todayItems = todayItems.reverse();
      followers = followers.reverse();
      this.setState({
        followers: data[0].value,
        chatsAmount: data[1].value,
        usersToday: data[2][0].usersAmount,
        searchAmount: data[2][0].search,
        itemsToday: data[2][0].addedChats || 0,
        words10: sorted10Words,
        words50: sorted50Words,
        chartLabels: labels,
        chartData: usersData,
        datasetUsers: usersData,
        datasetSearches: searchesData,
        datasetItems: todayItems,
        datasetFollowers: followers,
        dateTo: this.parseDate(data[2][0].createdAt),
        dateFrom: this.parseDate(data[2][data[2].length-1].createdAt),
        chats: data[4]
      });
    }).catch(error => {
      console.log(error)
    });
  }

  getChartData = () => {
    const { botURL } = this.props;
    const { dateFrom, dateTo } = this.state;
    let labels = [], usersData = [], searchesData = [], todayItems = [], followers = [];
   
    fetch(`${API_URL}/${botURL}/graph?from=${this.parseDate(dateFrom)}&to=${this.parseDate(dateTo)}`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      data.map(item => {
        labels.push(item.date);
        usersData.push(item.usersAmount);
        searchesData.push(item.search);
        todayItems.push(item.addedChats || 0);
        followers.push(item.newusers);
      });
      labels = labels.reverse();
      usersData = usersData.reverse();
      searchesData = searchesData.reverse();
      todayItems = todayItems.reverse();
      followers = followers.reverse();
      this.setState({
        chartLabels: labels,
        datasetUsers: usersData,
        datasetSearches: searchesData,
        datasetItems: todayItems,
        datasetFollowers: followers
      });
    }).catch(error => {
      console.log(error)
    });
  }

  parseDate = date => {
    const newDate = new Date(date);
    
    const y = newDate.getFullYear();
    const m = ("0" + (newDate.getMonth() + 1)).slice(-2);
    const d = ("0" + newDate.getDate()).slice(-2)
  
    return `${y}-${m}-${d}`;
  }

  setChart = (name, data) => {
    this.setState({
      chartName: name,
      chartData: data
    });
  }

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount () {
    if (this.props.botURL) {
      this.getData();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.botURL !== this.props.botURL) {
      this.getData();
    }
    if (prevState.dateFrom !== this.state.dateFrom ||
        prevState.dateTo !== this.state.dateTo) {
        this.getChartData();
      }
  }

  render() {
    let chart = {
      data: canvas => {
        let ctx = canvas.getContext("2d");
    
        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
    
        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)");
    
        return {
          labels: this.state.chartLabels,
          datasets: [
            {
              label: this.state.chartName,
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: "#1f8ef1",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#1f8ef1",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#1f8ef1",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.state.chartData
            }
          ]
        };
      },
      options: chart_options
    };

    return (
      <>
        <div className="content">
          <Row>
            <Col lg={{offset: 1, size: 2}}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Followers</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-single-02 text-info" />{" "}
                    {this.state.followers}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="2">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Users Today</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-sound-wave text-primary" />{" "}
                    {this.state.usersToday}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="2">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Search Amount</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-zoom-split text-success" />{" "}
                    {this.state.searchAmount}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="2">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Total Items</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-chat-33 text-warning" />{" "}
                    {this.state.chatsAmount}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="2">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Items Today</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-chat-33 text-danger" />{" "}
                    {this.state.itemsToday}
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="3">
                      <CardTitle tag="h2">Performance</CardTitle>
                    </Col>
                    <Col sm="4">
                      <Row>
                        <Col sm="6">
                          <Label>Date from</Label>
                          <Input
                            type="date"
                            max={this.state.dateTo}
                            value={this.state.dateFrom}
                            id="date-from"
                            name="dateFrom"
                            onChange={this.onInputChange}
                          />
                        </Col>
                        <Col sm="6">
                          <Label>Date from</Label>
                          <Input
                            type="date"
                            min={this.state.dateFrom}
                            max={this.parseDate(new Date())}
                            id="date-from"
                            name="dateTo"
                            value={this.state.dateTo}
                            onChange={this.onInputChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="5">
                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.chartName === "Followers"
                          })}
                          color="info"
                          id="0"
                          size="sm"
                          onClick={() => this.setChart('Followers', this.state.datasetFollowers)}
                        >
                          <input
                            defaultChecked
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Followers
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.chartName === "Users"
                          })}
                          color="info"
                          id="0"
                          size="sm"
                          onClick={() => this.setChart('Users', this.state.datasetUsers)}
                        >
                          <input
                            defaultChecked
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Users
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.chartName === "Items"
                          })}
                          color="info"
                          id="0"
                          size="sm"
                          onClick={() => this.setChart('Items', this.state.datasetItems)}
                        >
                          <input
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Items
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="1"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.chartName === "Searches"
                          })}
                          onClick={() => this.setChart('Searches', this.state.datasetSearches)}
                        >
                          <input
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Searches
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chart['data']}
                      options={chart.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="text=center">Daily Words</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter" responsive>
                    <tbody>
                      {this.state.words10.map((item, index) => {
                        return (<tr key={index} >
                          <td>{item.name}</td>
                          <td>{item.searches}</td>
                        </tr>);
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="text=center">Monthly Words</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter" responsive>
                    <tbody>
                    {this.state.words50.map((item, index) => {
                        return (<tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.searches}</td>
                        </tr>);
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col lg="5" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="text=center">Added Items Today</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter" responsive>
                    <thead>
                      <tr>
                        <td>Username</td>
                        <td className="text-center">Title</td>
                        <td className="text-right">Subs</td>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.chats.map((item, index) => {
                        return (<tr key={index}>
                          <td>{item.username}</td>
                          <td className="text-center">{item.title}</td>
                          <td className="text-right">{item.subs}</td>
                        </tr>);
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
