import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

import AdminLayout from "layouts/Admin/Admin.js";
import LoginLayout from "layouts/Login/Login.jsx";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";

const hist = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: Cookies.get('token')
    }
  }

  handleLogin = (response) => {
    this.setState({
      token: response.token
    });
    Cookies.set('token', response.token, {expires: 1000, path: '/'});
  }

  handleLogout = () => {
    this.setState({
      token: ''
    });
    Cookies.remove('token');
  }

  render() {
    return (
      <Router history={hist}>
        <Switch>
          {!this.state.token && <Route path="/login" render={props => <LoginLayout {...props} handleLogin={this.handleLogin} history={hist} />} />}
          {this.state.token && <Route path="/admin" render={props => <AdminLayout {...props} token={this.state.token} handleLogout={this.handleLogout} history={hist} />} />}
          <Redirect from="/" to={this.state.token ? "/admin/dashboard" : "/login"} />
        </Switch>
      </Router>
    );
  }
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
