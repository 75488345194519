import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody
} from "reactstrap";

import Pagination from '../components/Pagination/Pagination.jsx';

import { API_URL } from '../utils/api';

class Keywords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: "",
      modalEdit: false,
      modalAdd: false,
      newKeywordName: "",
      newChannelUsername: "",
      newChannelPosition: 0,
      newChannelDate: "",
      total_pages: 1,
      keywords: [],
      page: 1,
      editKeywordChannels: [],
      editKeyword: '',
      editKeywordId: '',
      searchPhrase: '',
      deleteKeywordId: ''
    }
  }

  parseDate = (date) => {
    const dateToParse = new Date(date);
    return dateToParse.toLocaleDateString('en-GB');
  };

  onFocus = () => {
    this.setState({
      focused: "input-group-focus"
    });
  };

  onBlur = (e) => {
    this.setState({
      focused: "",
    });
  };

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  modalInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  toggleModalEdit = () => {
    this.setState({
      modalEdit: !this.state.modalEdit
    });
  };

  toggleModalAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd
    });
  };

  addKeyword = (e) => {
    e.preventDefault();
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/create-keyword`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name: this.state.newKeywordName})
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      const updatedKeywords = [{channels: [], name: this.state.newKeywordName, _id: data}, ...this.state.keywords];
      this.setState({
        keywords: updatedKeywords
      });
      this.toggleModalAdd();
    }).catch(error => {
      console.log(error);
    });
  };

  getKeywords = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/keyword`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        total_pages: data[0].pages,
        keywords: data[1]
      });
    }).catch(error => {
      console.log(error);
    });
  };

  editKeyword = (_id) => {
    const { botURL } = this.props;
    this.toggleModalEdit();
    fetch(`${API_URL}/${botURL}/get-keyword?id=${_id}`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        editKeyword: data[0].name,
        editKeywordChannels: data[0].channels,
        editKeywordId: _id
      });
    }).catch(error => {
      console.log(error);
    });
  };

  deleteKeyword  = (_id) => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/delete-keyword`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: _id})
    }).then(this.handleErrors)
    .then( response => {
      const updatedKeywords = this.state.keywords.filter(item => item._id !== _id);
      this.setState({
        keywords: updatedKeywords
      });
    }).catch(error => {
      console.log(error);
    });
  }

  addChannel = (_id) => {
    const pos = this.state.newChannelPosition;
    const username = this.state.newChannelUsername;
    const date = this.state.newChannelDate;
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/edit-keyword`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({
        item_id: _id,
        position: pos,
        chat: username,
        due: date
      })
    }).then(this.handleErrors)
    .then( response => {
      const updatedChannels = [{chat : {username: username}, due: date, position: pos}, ...this.state.editKeywordChannels];
      this.setState({
        editKeywordChannels: updatedChannels
      }); 
    }).catch(error => {
      console.log(error);
    });
  }

  deleteChannelFromKeyword = (_id) => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/delete-chat-keyword`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({
        keyword_id: this.state.editKeywordId,
        chat_id: _id
      })
    }).then(this.handleErrors)
    .then( response => {
      const updatedChannels = this.state.editKeywordChannels.filter(channel => channel._id !== _id);
      this.setState({
        editKeywordChannels: updatedChannels
      }); 
    }).catch(error => {
      console.log(error);
    });
  }

  componentDidMount () {
    this.getKeywords();
  };

  switchPage = page => {
    this.setState({
      page: page
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { botURL } = this.props;
    if (prevState.editKeywordChannels !== this.state.editKeywordChannels) {
      this.getKeywords();
    }
    if (prevState.searchPhrase !== this.state.searchPhrase || prevState.page !== this.state.page) {
      fetch(`${API_URL}/${botURL}/keyword-search?page=${this.state.page}&name=${this.state.searchPhrase}`, {
        headers: {
          'Authorization': `Token ${this.props.token}`
        }
      }).then(this.handleErrors)
      .then( response => {
        return response.json();
      }).then(data => {
        this.setState({
          total_pages: data[0].pages,
          keywords: data[1]
        });
      }).catch(error => {
        console.log(error);
      });
    }
    if (prevProps.botURL !== this.props.botURL) {
      this.getKeywords();
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row className="row-equal-heigth">
            <Col sm={{offset: 1, size: 5}}>
              <Card>
                <CardBody>  
                  <InputGroup className={this.state.focused}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-zoom-split" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.modalInputChange}
                      type="search"
                      placeholder="Search..."
                      id="search-phrase"
                      name="searchPhrase"
                    />
                  </InputGroup>
                </CardBody>
              </Card>
            </Col>
            <Col sm="5" className="text-right">
              <Button color="info" onClick={this.toggleModalAdd}>Add Keyword</Button>
            </Col>
          </Row>
          <Row>
            <Col sm={{size: 10, offset: 1}}>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Keyword</th>
                        <th className="text-right">Paid channels</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.keywords.map(keyword => {
                        return (
                          <tr key={keyword._id}>
                            <td>{keyword.name}</td>
                            <td className="text-right">{keyword.channels.length}</td>
                            <td>
                              <Button className="btn-icon btn-simple" color="info" size="sm" onClick={() => {this.editKeyword(keyword._id)}}>
                                <i className="fa fa-edit"></i>
                              </Button>{` `}
                              <Button className="btn-icon btn-simple" color="danger" size="sm" onClick={() => {this.deleteKeyword(keyword._id)}}>
                                <i className="fa fa-times" />
                              </Button>{` `}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-center">
                    <Pagination page={this.state.page} total_pages={this.state.total_pages} switchPage={this.switchPage} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          modalClassName="modal-edit-keyword modal-black"
          isOpen={this.state.modalEdit}
          toggle={this.toggleModalEdit}
        >
          <div className="modal-header">
            <h5 className="modal-title">Keyword: {this.state.editKeyword}</h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalEdit}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
            <Table>
              <tbody>
                {this.state.editKeywordChannels.map((channel, index) => {
                  return (
                    <tr key={index}>
                      <td>{channel.chat.username}</td>
                      <td>{this.parseDate(channel.due)}</td>
                      <td>{channel.position}</td>
                      <td>
                        <Button className="btn-icon btn-simple" color="danger" size="sm" onClick={() => {this.deleteChannelFromKeyword(channel._id)}}>
                          <i className="fa fa-times" />
                        </Button>{` `}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Card>
              <CardBody>
                <form onSubmit={(e) => {e.preventDefault(); this.addChannel(this.state.editKeywordId)}}>
                  <p>Add new channel</p>
                  <Row>
                    <FormGroup className="col-12">
                      <Input 
                        type="text"
                        id="channel-username"
                        placeholder="Name"
                        name="newChannelUsername"
                        onChange={this.modalInputChange}
                      />
                    </FormGroup>
                    <FormGroup className="col-6">
                      <Input
                        type="date"
                        id="channel-date"
                        placeholder="Valid date"
                        name="newChannelDate"
                        onChange={this.modalInputChange}
                      />
                    </FormGroup>
                    <FormGroup className="col-6">
                      <Input
                        type="number"
                        id="channel-position"
                        placeholder="Position (1-20)"
                        name="newChannelPosition"
                        onChange={this.modalInputChange}
                      />
                    </FormGroup>
                  </Row>
                  <div className="text-right">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={(e) => {e.preventDefault(); this.addChannel(this.state.editKeywordId)}}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        <Modal
          modalClassName="modal-add-keyword modal-black"
          isOpen={this.state.modalAdd}
          toggle={this.toggleModalAdd}
        >
          <div className="modal-header">
            <h5 className="modal-title">Add new keyword</h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalAdd}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
            <form onSubmit={this.addKeyword}>
              <Row>
                <FormGroup className="col-12">
                  <Input 
                    type="text"
                    id="keyword-name"
                    placeholder="Name"
                    name="newKeywordName"
                    onChange={this.modalInputChange}
                  />
                </FormGroup>
              </Row>
              <div className="text-right">
                <Button
                  color="primary"
                  type="submit"
                  onClick={(this.addKeyword)}
                >
                  Save
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Keywords;
