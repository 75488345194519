import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { API_URL } from '../../utils/api';

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      sidebarOpened: document.documentElement.className.indexOf("nav-open") !== -1,
      adminLevel: 3,
      bots: [],
      selectedBot: {}
    };
  }l
  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
  filterRoutes = routes => {
    return routes.filter(route => route.adminLevel >= this.state.adminLevel);
  }
  componentDidMount() {
    fetch(`${API_URL}/authApi/current`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        adminLevel: data.level
      });
    }).catch(error => {
      console.log(error);
    });


    fetch(`${API_URL}/authApi/botList`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        bots: data,
        selectedBot: data[0]
      });
    }).catch(error => {
      console.log(error);
    });

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        tables[i].classList.add("position-relative", "pr-2");
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e, prevState) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          tables[i].classList.add("position-relative", "pr-2");
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  onBotSelect = id => {
    const selectedBotIndex = this.state.bots.findIndex(item => item._id === id);
    this.setState({
      selectedBot: this.state.bots[selectedBotIndex]
    });
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && (prop.adminLevel >= this.state.adminLevel)) {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={props => <prop.component {...props} token={this.props.token} adminLevel={this.state.adminLevel} botURL={this.state.selectedBot.way}/>}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {

    return (
      <>
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={this.filterRoutes(routes)}
            bgColor={this.state.backgroundColor}
            logo={{
              text: "DinaSor Bot",
            }}
            toggleSidebar={this.toggleSidebar}
          />
          <div
            className="main-panel position-relative"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
              handleLogout={this.props.handleLogout}
              history={this.props.history}
              bots={this.state.bots}
              selectedBot={this.state.selectedBot}
              onBotSelect={this.onBotSelect}
            />
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="*" to="/admin/dashboard"/>
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default Admin;
