import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
  Row,
  Col,
  InputGroupText,
  Modal,
  ModalBody,
  Label,
  Alert
} from "reactstrap";

import Pagination from '../components/Pagination/Pagination.jsx';

import { API_URL } from '../utils/api';

class Database extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: "",
      modalEdit: false,
      modalDelete: false,
      total_pages: 1,
      databaseList: [],
      labels: [],
      page: 1,
      activeLabels: [],
      subsFrom: 0,
      subsTo: 9999999,
      editEntry: "",
      editEntryName: "",
      editEntrySubs: 0,
      editEntryLabels: [],
      searchPhrase: "",
      deleteEntry: "",
      dateFrom: new Date(0),
      dateTo: new Date(),
      alert: false
    };
  }

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  getData = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/database`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        total_pages: data[0].pages,
        databaseList: data[1],
        labels: data[2],
      });
    }).catch(error => {
      console.log(error);
    });
  };

  parseDate = (date) => {
    const dateToParse = new Date(date);
    
    const y = dateToParse.getFullYear();
    const m = ("0" + (dateToParse.getUTCMonth() + 1)).slice(-2);
    const d = ("0" + dateToParse.getUTCDate()).slice(-2)
  
    return `${d}/${m}/${y}`;
  };

  parseLables = (activeLables) => {
    let activeLabelsString = "";
    activeLables.map(item => {
      activeLabelsString = activeLabelsString ? activeLabelsString + "," + item : item;
    });
    return activeLabelsString;
  }

  addActiveLabel = (_id) => {
    const updatedActiveLabels = [...this.state.activeLabels, _id];
    this.setState({
      activeLabels: updatedActiveLabels
    });
  };

  removeActiveLabel = (_id) => {
    let updatedActiveLabels = this.state.activeLabels.slice();
    const removeIndex = updatedActiveLabels.indexOf(_id);
    updatedActiveLabels.splice(removeIndex, 1);
    this.setState({
      activeLabels: updatedActiveLabels
    });
  };

  onFocus = () => {
    this.setState({
      focused: "input-group-focus"
    });
  };

  onBlur = (e) => {
    this.setState({
      focused: ""
    });
  };

  toggleModalEdit = () => {
    this.setState({
      modalEdit: !this.state.modalEdit,
    });
  };

  toggleModalDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
    });
  };

  prepareEdit = (entryId) => {
    const editingItem = this.state.databaseList.find((item) => item._id === entryId);
    
    this.setState({
      editEntry: entryId,
      editEntryName: editingItem.title,
      editEntrySubs: editingItem.subs,
      editEntryLabels: editingItem.lables || []
    });
    this.toggleModalEdit();
  };

  prepareDelete = (entryId) => {
    this.setState({
      deleteEntry: entryId
    });
    this.toggleModalDelete();
  };

  editEntry = (e) => {
    e.preventDefault();
    const {page, searchPhrase, activeLabels, subsFrom, subsTo, dateFrom, dateTo } = this.state;
    const { botURL } = this.props;
    
    fetch(`${API_URL}/${botURL}/edit-db`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: this.state.editEntry, name: this.state.editEntryName, subs: this.state.editEntrySubs, lables: this.state.editEntryLabels})
    }).then(this.handleErrors)
    .then( response => {
      this.toggleModalEdit();
      this.updateData(page, searchPhrase, this.parseLables(activeLabels), subsFrom, subsTo, dateFrom, dateTo);
    }).catch(error => {
      console.log(error);
    });  
  };

  deleteEntry = (blacklist) => {
    const {page, searchPhrase, activeLabels, subsFrom, subsTo, dateFrom, dateTo } = this.state;
    const { botURL } = this.props;

    fetch(`${API_URL}/${botURL}/delete-db`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
      body: JSON.stringify({id: this.state.deleteEntry, blacklist: blacklist})
    }).then(this.handleErrors)
    .then( response => {
      this.toggleModalDelete();
      this.updateData(page, searchPhrase, this.parseLables(activeLabels), subsFrom, subsTo, dateFrom, dateTo);
    }).catch(error => {
      console.log(error);
    });
  };
  
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  
  switchPage = page => {
    this.setState({
      page: page
    });
  };
  
  updateSubs = () => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/updatesubs`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      this.setState({
        alert: true
      });
    }).catch(error => {
      console.log(error);
    });
  };

  addLabelToEntry = (labelId) => {
    const updatedLabels = [...this.state.editEntryLabels, labelId];
    this.setState({
      editEntryLabels: updatedLabels
    });
  };

  removeLabelFromEntry = (labelId) => {
    let updatedEntryLabels = this.state.editEntryLabels.slice();
    const removeindex = this.state.editEntryLabels.indexOf(labelId);
    updatedEntryLabels.splice(removeindex, 1);
    this.setState({
      editEntryLabels: updatedEntryLabels
    });
  };

  alertOnDismiss = () => {
    this.setState({
      alert: false
    });
  }

  updateData = (page, searchPhrase, activeLabelsString, subsFrom, subsTo, dateFrom, dateTo) => {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/dbsearch?page=${page}&lables=${activeLabelsString}&from=${subsFrom}&to=${subsTo}&search=${searchPhrase}&date_start=${dateFrom}&date_finish=${dateTo}`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        total_pages: data[0].pages,
        databaseList: data[1]
      });
    }).catch(error => {
      console.log(error);
    });
  };
  
  componentDidUpdate (prevProps, prevState) {
    const {page, searchPhrase, activeLabels, subsFrom, subsTo, dateFrom, dateTo } = this.state;
    if (prevState.page !== page ||
      prevState.searchPhrase !== searchPhrase ||
      prevState.activeLabels.length !== activeLabels.length ||
      prevState.subsFrom !== subsFrom ||
      prevState.subsTo !== subsTo ||
      prevState.dateFrom !== dateFrom ||
      prevState.dateTo !== dateTo) {
        this.updateData(page, searchPhrase, this.parseLables(activeLabels), subsFrom, subsTo, dateFrom, dateTo);
    }
    if (prevProps.botURL !== this.props.botURL) {
      this.getData();
    }
  };

  componentDidMount () {
    this.getData(); 
  };

  render() {
    const { adminLevel } = this.props;

    return (
      <>
        <div className="content">
          <Row className="row-equal-heigth">
            <Col sm="10">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="4">  
                      <Label>Search</Label>
                      <InputGroup className={this.state.focused}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-zoom-split" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onChange={this.onInputChange}
                          type="search"
                          placeholder="Search..."
                          id="search-phrase"
                          name="searchPhrase"
                        />
                      </InputGroup>
                    </Col>
                    <Col xs="2">
                      <Label>Subs from</Label>
                      <Input
                        type="number"
                        min="0"
                        placeholder="From"
                        id="search-from"
                        name="subsFrom"
                        onChange={this.onInputChange}
                      />
                    </Col>
                    <Col xs="2">
                      <Label>Subs to</Label>
                      <Input 
                        type="number"
                        min="0"
                        placeholder="To"
                        id="search-to"
                        name="subsTo"
                        onChange={this.onInputChange}
                      />
                    </Col>
                    <Col xs="2">
                      <Label>Date from</Label>
                      <Input
                        type="date"
                        id="search-date-from"
                        name="dateFrom"
                        onChange={this.onInputChange}
                      />
                    </Col>
                    <Col xs="2">
                      <Label>Date to</Label>
                      <Input 
                        type="date"
                        id="search-date-to"
                        name="dateTo"
                        onChange={this.onInputChange}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {adminLevel <= 2 &&
            (<Col sm="2" className="text-right">
              <Button color="info" onClick={this.updateSubs}>Update Subs</Button>
            </Col>)}
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Labels</CardTitle>
                </CardHeader>
                <CardBody>
                  {this.state.labels.map((item) => {
                    return (
                      this.state.activeLabels.find(currVal => item._id === currVal) ?
                      <Button key={item._id} color="success" className="btn-round" size="sm" onClick={() => {this.removeActiveLabel(item._id)}}>{item.name}</Button> :
                      <Button key={item._id} color="default" className="btn-round" size="sm" onClick={() => {this.addActiveLabel(item._id)}}>{item.name}</Button>
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Username</th>
                        <th>Type</th>
                        <th>Added</th>
                        <th>Subs</th>
                        {adminLevel <= 2 && <th></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.databaseList.map(item => {
                        return (
                          <tr key={item._id}>
                            <td>{item.title}</td>
                            <td>{item.username}</td>
                            <td>{item.type}</td>
                            <td>{this.parseDate(item.added)}</td>
                            <td>{item.subs}</td>
                            {adminLevel <= 2 && 
                            (<td>
                              <Button className="btn-icon btn-simple" color="info" size="sm" onClick={() => {this.prepareEdit(item._id)}}>
                                <i className="fa fa-edit"></i>
                              </Button>{` `}
                              <Button className="btn-icon btn-simple" color="danger" size="sm" onClick={() => {this.prepareDelete(item._id)}}>
                                <i className="fa fa-times" />
                              </Button>{` `}
                            </td>)}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-center">
                    <Pagination page={this.state.page} total_pages={this.state.total_pages} switchPage={this.switchPage} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="react-notification-alert-container">
            <Alert color="success" isOpen={this.state.alert} toggle={this.alertOnDismiss} className="alert-dismissible">
              Subscribers updated successfully!
            </Alert>
          </div>
        </div>
        <Modal
          modalClassName="modal-edit-entry modal-black"
          isOpen={this.state.modalEdit}
          toggle={this.toggleModalEdit}
        >
          <div className="modal-header">
            <h5 className="modal-title">Edit entry</h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalEdit}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
            <form onSubmit={this.editEntry}>
              <FormGroup>
                <Label>Name</Label>
                <Input 
                  type="text"
                  id="entry-name-edit"
                  placeholder="Name"
                  onChange={this.onInputChange}
                  name="editEntryName"
                  value={this.state.editEntryName}
                />
              </FormGroup>
              <FormGroup>
                <Label>Subs</Label>
                <Input
                  type="number"
                  id="entry-subs-edit"
                  placeholder="Subs"
                  onChange={this.onInputChange}
                  name="editEntrySubs"
                  value={this.state.editEntrySubs}
                />
              </FormGroup>
              <Row>
                <Col>
                  {this.state.labels.map((item) => {
                    return (
                      this.state.editEntryLabels.find(currVal => item._id === currVal) ?
                      <Button key={item._id} color="success" className="btn-round" size="sm" onClick={() => {this.removeLabelFromEntry(item._id)}}>{item.name}</Button> :
                      <Button key={item._id} color="default" className="btn-round" size="sm" onClick={() => {this.addLabelToEntry(item._id)}}>{item.name}</Button>
                    );
                  })}
                </Col>
              </Row>
              <div className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  onClick={this.editEntry}
                >
                  Save
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal
          modalClassName="modal-edit-entry modal-black"
          isOpen={this.state.modalDelete}
          toggle={this.toggleModalDelete}
        >
          <div className="modal-header">
            <h5 className="modal-title">Delete entry</h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalDelete}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <ModalBody>
          <Row>
            <Col className="text-right">
              <Button color="default" onClick={() => {this.deleteEntry(false)}}>Delete</Button>
              <Button color="danger" onClick={() => {this.deleteEntry(true)}}>Delete & Blacklist</Button>
            </Col>
          </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Database;
