import React from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";

import { API_URL } from '../utils/api';

class Deposits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deposits: [],
      purchases: []
    }
  }

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  getData() {
    const { botURL } = this.props;
    fetch(`${API_URL}/${botURL}/records`, {
      headers: {
        'Authorization': `Token ${this.props.token}`
      }
    }).then(this.handleErrors)
    .then( response => {
      return response.json();
    }).then(data => {
      this.setState({
        deposits: data[0],
        purchases: data[1]
      });
    }).catch(error => {
      console.log(error);
    });
  };

  parseDate = date => {
    const dateToParse = new Date(date);
    
    const y = dateToParse.getFullYear();
    const m = ("0" + (dateToParse.getUTCMonth() + 1)).slice(-2);
    const d = ("0" + dateToParse.getUTCDate()).slice(-2);
    const h = ("0" + dateToParse.getUTCHours()).slice(-2);
    const mi = ("0" + dateToParse.getUTCMinutes()).slice(-2);
    const s = ("0" + dateToParse.getUTCSeconds()).slice(-2);
  
    return `${h}:${mi}:${s}, ${d}/${m}/${y}`;
  }

  formatPrice = price => price/1000000000000000000;

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Deposits</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Telegram ID</th>
                        <th>Hash</th>
                        <th>Amount</th>
                        <th className="text-right">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.deposits.map(item => {
                        return (
                          <tr key={item._id}>
                            <td>{item.tgId}</td>
                            <td>{item.hashId}</td>
                            <td>{this.formatPrice(item.ammount)}</td>
                            <td className="text-right">{this.parseDate(item.time)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Purchases</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Telegram ID</th>
                        <th>AD Type</th>
                        <th>Amount</th>
                        <th>Days</th>
                        <th>Params</th>
                        <th className="text-right">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.purchases.map(item => {
                        return (
                          <tr key={item._id}>
                            <td>{item.tgId}</td>
                            <td>{item.adType}</td>
                            <td>{this.formatPrice(item.ammount)}</td>
                            <td>{item.days}</td>
                            <td>{
                            item.adType === 'ad' 
                            ? `Text : ${item.text}, URL : ${item.url}` 
                            : `Chat : ${item.chat}, Position : ${item.position}, Keyword : ${item.keyword}`  
                            }</td>
                            <td className="text-right">{this.parseDate(item.time)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Deposits;